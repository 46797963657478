import type { NewsArticle, SpeakableSpecification, WithContext } from 'schema-dts';

import { useTranslation } from '@cms/i18n/client';
import { LocaleID, News } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { formatRoute, Route } from '@web/routing';

import { StructuredData } from './atoms/StructuredData';

export interface Props {
    article: News | undefined;
    speakable?: Partial<SpeakableSpecification>;
}

export const NewsArticleStructuredData = ({ article, speakable }: Props) => {
    const { context } = useContextData();
    const __url = useTranslation('url').t;

    if (!article) return null;

    const currentUrl = `${article.host}${article.metaPath || article.path}`;

    const data: WithContext<NewsArticle> = {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        '@id': currentUrl,
        name: article.metaTitle || article.newsTitle,
        headline: article.newsTitle,
        alternativeHeadline: article.experiment?.experimentTitle,
        description: article.newsBold?.replace(/<[^>]+>/g, ''),
        url: currentUrl,
        mainEntityOfPage: {
            '@id': currentUrl,
        },
        inLanguage: context.locale,
        publisher: {
            '@id': formatRoute(context, Route.Homepage, __url),
        },
    };

    if (article.authorUserID) {
        const authorUrl = formatRoute(context, Route.User, __url, {
            slug: article.author?.customSlug ? article.author.customSlug : article.authorUserID,
        });
        data['author'] = {
            '@type': 'Person',
            '@id': `${authorUrl}#author`,
            url: authorUrl,
            name: article.author?.fullName,
        };
    }

    if (article.image) data['image'] = article.image.replace('{WebpWidth}', `${WebpWidthEnum.WIDTH_1200}`);

    const formattedNewsDate = article.newsDate
        ? DateTimeUtil.format(article.newsDate, Format.DATE_TIME_ISO)
        : undefined;

    const formattedNewsPublishDate = article.newsPublishDate
        ? DateTimeUtil.format(article.newsPublishDate, Format.DATE_TIME_ISO)
        : undefined;

    if (article.newsPublishDate && article.newsDate < article.newsPublishDate) {
        data['dateCreated'] = formattedNewsDate;
        data['datePublished'] = formattedNewsPublishDate;
    } else if (article.newsPublishDate) {
        data['dateCreated'] = formattedNewsPublishDate;
        data['datePublished'] = formattedNewsPublishDate;
    } else if (article.newsDate) {
        data['dateCreated'] = formattedNewsDate;
        data['datePublished'] = formattedNewsDate;
    }

    if (article.newsDateUpdate)
        data['dateModified'] = DateTimeUtil.format(article.newsDateUpdate, Format.DATE_TIME_ISO);

    if (speakable && context.locale === LocaleID.EN) {
        data['speakable'] = {
            '@type': 'SpeakableSpecification',
            ...speakable,
        };
    }

    return <StructuredData data={data} entity={'NewsArticle'} />;
};
