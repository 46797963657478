import { ExperimentGroup, ExperimentGroupOrRobots, NewsService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';

/**
 * Type predicate to check if the group is ExperimentGroup
 */
export const isNotRobot = (group?: ExperimentGroup | ExperimentGroupOrRobots): group is ExperimentGroup =>
    group !== 'robots' && typeof group !== 'undefined';

export const increaseVisitCount = async (
    contextData: ContextData,
    newsID: number,
    experimentGroup: ExperimentGroup,
) => {
    const { data } = await ApiService({
        contextData,
        isClientSide: true,
        service: NewsService,
    }).increaseVisitCountNewsByDomainId({
        path: { contextID: contextData.context.id, newsID },
        query: { experimentGroup },
    });

    return data;
};
