import { ComponentProps } from 'react';
import dynamic from 'next/dynamic';

import type { CommentsThreadLoader as CommentsThreadLoader } from './CommentsThreadLoader';

const CommentsThreadImport = dynamic<ComponentProps<typeof CommentsThreadLoader>>(
    () => import('./CommentsThreadLoader').then((mod) => mod.CommentsThreadLoader),
    { ssr: false },
);

const CommentsThreadPlatformFilter = (props: ComponentProps<typeof CommentsThreadLoader>) => {
    return <CommentsThreadImport {...props} />;
};

export const CommentsThread = dynamic<ComponentProps<typeof CommentsThreadLoader>>(
    async () => CommentsThreadPlatformFilter,
    {
        ssr: false,
    },
);
