import { type ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

export interface NewsHeaderThemeConfig {
    componentOrder: Array<'title' | 'image' | 'author' | 'date' | 'intro'>;
    showComments: boolean;
    showShareBlock: boolean;
}

const THEME_CONFIG: ThemeConfig<NewsHeaderThemeConfig> = {
    defaults: {
        showComments: true,
        showShareBlock: true,
        componentOrder: ['title', 'date', 'author', 'intro', 'image'],
    },
    byPlatform: {
        bi: { showComments: false, showShareBlock: false },
        mh: { showComments: false, showShareBlock: false },
        rw: { showComments: false, showShareBlock: false },
        wh: { showComments: false, showShareBlock: false },
        vn: { componentOrder: ['title', 'image', 'date', 'author', 'intro'] },
        vp: { componentOrder: ['image', 'title', 'date', 'author', 'intro'] },
        br: { componentOrder: ['image', 'title', 'date', 'author', 'intro'] },
    },
};

export const useNewsHeaderThemeConfig = () => useThemeConfig(THEME_CONFIG);
