import { ModuleScheduleItem, ThreeWayOdds } from '@common/clients/api/types.gen';

import { formatOddsUrl } from '.';

export const formatOddsGroupsUrls = (
    moduleScheduleItem: ModuleScheduleItem,
    odds: ThreeWayOdds[],
): ThreeWayOdds[] => {
    if (!odds) return odds;

    return odds.map((threewayOdd) => {
        return formatOddsUrl(moduleScheduleItem, threewayOdd);
    });
};
