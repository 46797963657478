import { VideofeedItemPublicScope } from '@common/clients/api';

export enum VideoCarouselPlacement {
    HEADER = 'header',
    BODY = 'body',
    FOOTER = 'footer',
    FOOTER_NO_VIDEOFEEDITEM = 'footer-no-videofeeditem',
}

/**
 * Get the enum VideoCarouselPlacement of the video carousel based on videofeedItem.position
 *
 * @example
 * const placement = getVideoCarouselPlacement(videofeedItem);
 */
export const getVideoCarouselPlacement = (
    videofeedItem?: VideofeedItemPublicScope,
): VideoCarouselPlacement => {
    if (!videofeedItem) {
        return VideoCarouselPlacement.FOOTER_NO_VIDEOFEEDITEM;
    }
    const position = videofeedItem.position;
    switch (position) {
        case -1:
            return VideoCarouselPlacement.HEADER;
        case undefined:
            return VideoCarouselPlacement.FOOTER;
        default:
            return VideoCarouselPlacement.BODY;
    }
};
