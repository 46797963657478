import type { VideoObject, WithContext } from 'schema-dts';

import { News, VideofeedItemPublicScope } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { stripHtml } from '@common/utils/stripHtml';

import { StructuredData } from './atoms/StructuredData';

export interface Props {
    article?: News;
    video?: VideofeedItemPublicScope;
}

export const VideoStructuredData = ({ article, video }: Props) => {
    const { env, platform } = useContextData();

    if (!article) return null;
    const publicAssetPrefix = env.NEXT_PUBLIC_ASSET_PREFIX;

    const videoFileData = {
        embed: '',
        url: '',
        videoDate: DateTimeUtil.format(new Date(article.newsDate), Format.DATE_TIME_ISO),
        thumb: article.image?.replace('{WebpWidth}', `${WebpWidthEnum.WIDTH_1200}`),
        placeholder: `${publicAssetPrefix || ''}/images/${platform.id}/placeholder.jpg`,
    };

    const part = article.newsVideo?.split('/').reverse() || [''];
    if (article.newsVideo?.includes('youtu')) {
        videoFileData.embed = `https://www.youtube.com/embed/${part[0]}`;
    } else if (article.newsVideo?.includes('dai.ly')) {
        videoFileData.embed = `https://www.dailymotion.com/embed/video/${part[0]}`;
    } else if (article.newsVideo?.includes('twitter.com')) {
        const urlMatch = article.newsVideo?.match(/<a href="(https:\/\/twitter\.com\/[^"]+)"/);
        if (urlMatch && urlMatch?.length >= 2) videoFileData.embed = urlMatch[1];
    } else if (article.newsVideo) {
        videoFileData.embed = article.newsVideo;
    } else if (video?.url) {
        videoFileData.url = video?.url;
    }

    if (!videoFileData.url && !videoFileData.embed) return null;

    const data: WithContext<VideoObject> = {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: stripHtml(article.newsTitle),
        thumbnailUrl: videoFileData.thumb ?? videoFileData.placeholder,
        uploadDate: videoFileData.videoDate,
    };

    if (videoFileData.url) {
        data.contentUrl = videoFileData.url;
    } else if (videoFileData.embed) {
        const cleanEmbedText = stripHtml(videoFileData.embed);
        if (videoFileData.embed.includes('<script')) {
            // FIXME: PB-4934 - Contains closing script tag
            return null;
        } else {
            data.embedUrl = cleanEmbedText;
        }
    } else {
        return null;
    }

    if (article?.newsBold) data.description = stripHtml(article.newsBold);

    return <StructuredData data={data} entity={'VideoObject'} />;
};
