import dynamic from 'next/dynamic';

import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { FullContext, ModuleScheduleItem, News, VideofeedItemPublicScope } from '@common/clients/api';
import { NewsFunctionality } from '@common/types/NewsFunctionality';
import { useContextData } from '@common/useContextData';
import { HtmlToReact } from '@web/atoms/HtmlToReact';
import { getAdminUrl } from '@web/utils/getAdminUrl';

import { ShareBlock } from '../../molecules/ShareBlock';
import { getVideoCarouselPlacement, VideoCarouselPlacement } from '../../utils';
import { AuthorMeta, DateMeta, Hero } from './molecules';
import { useNewsHeaderThemeConfig } from './NewsHeader.config';

import styles from './NewsHeader.module.scss';

const ArticlePromoItem = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/ArticlePromoItem').then((module) => module.ArticlePromoItem),
);

export const VideoCarousel = dynamic(() =>
    import('@web/organisms/VideoCarousel/VideoCarousel').then((mod) => mod.VideoCarousel),
);

interface Props {
    news: News;
    context: FullContext;
    moduleScheduleItem?: ModuleScheduleItem;
}

export const NewsHeader = ({ news, context, moduleScheduleItem }: Props) => {
    const { platform } = useContextData();
    const __articles = useTranslation('articles').t;

    const themeConfig = useNewsHeaderThemeConfig();

    const adminUrl = `${getAdminUrl(context.url)}/${news.category?.isMedia ? 'media' : 'artikel'}/${
        news.newsID
    }`;

    const videoCarouselPlacement = getVideoCarouselPlacement(news?.videofeedItem);
    const showVideoCarousel = videoCarouselPlacement === VideoCarouselPlacement.HEADER;

    const showPromo = !!(moduleScheduleItem && news.adsEnabled);
    const isPremium = news.category?.functionality === NewsFunctionality.PREMIUM;

    const renderComponent = {
        title: (
            <>
                {news.newsSubTitle ? (
                    <span className={`tag large ${styles['tag']}`}>{news.newsSubTitle}</span>
                ) : null}
                <h1>{news.newsTitle}</h1>
            </>
        ),
        image: !showVideoCarousel ? (
            <Hero adminUrl={adminUrl} news={news} __translate={__articles} isPremium={isPremium} />
        ) : null,
        author: (
            <div className={styles['header-content']}>
                {news.author ? <AuthorMeta isPremium={isPremium} author={news.author} /> : null}
            </div>
        ),
        date: (
            <div className={styles['header-content']}>
                <div className={styles['meta-container']}>
                    <DateMeta
                        newsDate={news?.newsDate}
                        newsDateUpdate={news?.newsDateUpdate}
                        __translate={__articles}
                        locale={context.locale}
                        isPremium={isPremium}
                    />

                    {themeConfig.showComments && news.commentCount ? (
                        <a className={styles['comment-count']} href={'#comments_anchor'}>
                            <Icon.comment />
                            {news.commentCount}
                        </a>
                    ) : null}

                    {themeConfig.showShareBlock ? (
                        <div className={styles['share-section']}>
                            <ShareBlock
                                baseUrl={news.host}
                                newsTitle={news.newsTitle || ''}
                                newsPath={news.path || ''}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        ),
        intro: (
            <>
                {news.newsBold ? (
                    <strong className={styles.intro}>
                        <HtmlToReact html={news.newsBold} />
                    </strong>
                ) : null}
            </>
        ),
    };

    return (
        <header className={styles['NewsHeader']}>
            {showVideoCarousel && news?.videofeedItem?.id ? (
                <>
                    <VideoCarousel
                        mainVideo={{
                            ...(news?.videofeedItem as VideofeedItemPublicScope),
                            path: `${news.host}${news.path}`,
                        }}
                    />
                    {showPromo ? (
                        <ArticlePromoItem platform={platform?.id} moduleScheduleItem={moduleScheduleItem} />
                    ) : null}
                </>
            ) : null}
            {themeConfig.componentOrder.map((name) => renderComponent[name])}
        </header>
    );
};
