import clsx from 'clsx';

import { NewsCategory, PartialTag } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { RouteByTagType } from '@web/routing';

import styles from './TagList.module.scss';

export interface Props {
    className?: string;
    customRoute?: Route;
    tags: PartialTag[];
    category?: NewsCategory;
}

export const TagList = ({ category, tags, customRoute, className }: Props) => {
    if (!tags.length && !category?.showAsTag) {
        return null;
    }

    const hasBettingTag = tags.find((tag) => tag.tagSlug === 'wedden');

    return (
        <ul className={clsx(styles['tag-list'], className)}>
            {category?.showAsTag && (category.functionality !== 'betting' || !hasBettingTag) ? (
                <li key={`newsCat`}>
                    <InternalLink
                        route={Route.CategoryNews}
                        query={{ slug: category.slug }}
                        className="tag medium"
                    >
                        {category.name}
                    </InternalLink>
                </li>
            ) : null}
            {tags.map((tag, index) =>
                tag.tagType ? (
                    <li key={index}>
                        <InternalLink
                            className="tag medium"
                            isSponsored={customRoute ? true : false}
                            route={customRoute ? customRoute : RouteByTagType[tag.tagType]}
                            query={tag.tagSlug ? { tag: tag.tagSlug } : undefined}
                        >
                            {tag.title}
                        </InternalLink>
                    </li>
                ) : null,
            )}
        </ul>
    );
};
