import { Match, MatchService, ModuleScheduleItem } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';

export const getMatch = async ({
    contextData,
    matchID,
    moduleScheduleItem,
}: {
    contextData: ContextData;
    matchID: string;
    moduleScheduleItem?: ModuleScheduleItem;
}): Promise<Match> => {
    const { data } = await ApiService({
        contextData,
        isClientSide: true,
        service: MatchService,
        config: {
            throwOnError: true,
        },
    }).getMatchDetailsMatch({
        path: {
            id: matchID,
        },
        query: {
            bookmakerID: moduleScheduleItem?.bookmaker?.id,
            secondBookmakerID: moduleScheduleItem?.secondaryBookmaker?.id,
        },
        throwOnError: true,
    });

    return data;
};
