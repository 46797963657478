import { memo, ReactElement, useMemo } from 'react';
import dynamic from 'next/dynamic';

import { useTranslation } from '@cms/i18n/client';
import { Match, ModuleScheduleItem, ModuleScheduleSection, News, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { HtmlToReact } from '@web/atoms/HtmlToReact';
import { ModulesBySection } from '@web/handlers/fetchActiveModules';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { RichNextContent } from '@web/molecules/RichNextContent';
import { VideoCarousel as ClientVideoCarousel } from '@web/organisms/VideoCarousel/ClientSideVideoCarousel';
import { VideoCarousel as OnScrollVideoCarousel } from '@web/organisms/VideoCarousel/OnScrollLoadedVideoCarousel';

import { getVideoCarouselPlacement, VideoCarouselPlacement } from '../../utils';

import styles from './NewsBody.module.scss';

const MatchBlock = dynamic(() =>
    import('@web/templates/NewsDetail/molecules/MatchBlock').then((module) => module.MatchBlock),
);

const ArticleMatchOdds = dynamic(() =>
    import('@web/organisms/TinyMatchBlock').then((module) => module.TinyMatchBlock),
);

const SimpleNewsList = dynamic(() =>
    import('@web/molecules/SimpleNewsList').then((module) => module.SimpleNewsList),
);

const ArticlePromoItem = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/ArticlePromoItem').then((module) => module.ArticlePromoItem),
);

export interface Props {
    news: News;
    upcomingMatch?: Match;
    moduleScheduleItem?: ModuleScheduleItem;
    modulesBySection?: ModulesBySection;
}

const Component = ({ news, upcomingMatch, moduleScheduleItem }: Props) => {
    const { platform } = useContextData();
    const { videofeedItem } = news;

    const __articles = useTranslation('articles').t;

    const [injections, bottomInjections] = useMemo(() => {
        const injections: ReactElement[] = [];
        const bottomInjections: ReactElement[] = [];

        if (news.adsEnabled !== false) {
            injections[1] = (
                <Ad
                    key="ARTICLE_AFTER_PARAGRAPH_1"
                    isOptional
                    placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_1}
                />
            );

            if (platform?.id === PlatformID.VI) {
                const match = upcomingMatch;
                const bookmaker = match?.odds?.length ? match?.odds[0]?.bookmaker : undefined;

                injections[2] = (
                    <>
                        {upcomingMatch ? (
                            <ArticleMatchOdds
                                trackerName={ModuleScheduleSection.ARTICLE_MATCHES}
                                match={match!}
                                bookmaker={bookmaker}
                            />
                        ) : null}
                        <Ad
                            key="ARTICLE_AFTER_PARAGRAPH_2"
                            isOptional
                            placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_2}
                        />
                    </>
                );
            } else {
                injections[2] = (
                    <>
                        {upcomingMatch ? <MatchBlock match={upcomingMatch} /> : null}
                        <Ad
                            key="ARTICLE_AFTER_PARAGRAPH_2"
                            isOptional
                            placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_2}
                        />
                    </>
                );
            }
            injections[3] = (
                <>
                    <Ad
                        key="ARTICLE_AFTER_PARAGRAPH_3"
                        isOptional
                        placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_3}
                    />
                    {injections[3]}
                </>
            );
            injections[4] = (
                <Ad
                    key="ARTICLE_AFTER_PARAGRAPH_4"
                    isOptional
                    placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_4}
                />
            );
            injections[7] = (
                <Ad
                    key="ARTICLE_AFTER_PARAGRAPH_7"
                    isOptional
                    placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_7}
                />
            );
            injections[10] = (
                <Ad
                    key="ARTICLE_AFTER_PARAGRAPH_10"
                    isOptional
                    placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_10}
                />
            );
            injections[13] = (
                <Ad
                    key="ARTICLE_AFTER_PARAGRAPH_13"
                    isOptional
                    placement={AdPlacement.ARTICLE_AFTER_PARAGRAPH_13}
                />
            );
            bottomInjections.push(
                <Ad key="ARTICLE_AFTER_CONTENT" isOptional placement={AdPlacement.ARTICLE_AFTER_CONTENT} />,
            );
        }

        /** Readmore */
        news.readMore?.forEach((readMoreBlock) => {
            const readMore = (
                <SimpleNewsList
                    key={`readmore-${readMoreBlock.position}`}
                    articles={readMoreBlock.articles}
                    title={__articles`readmore-block.title`}
                />
            );

            if (readMoreBlock.position === 0) {
                bottomInjections.push(readMore);
            } else if (injections[readMoreBlock.position]) {
                injections[readMoreBlock.position] = (
                    <>
                        {injections[readMoreBlock.position] ? injections[readMoreBlock.position] : null}
                        <SimpleNewsList
                            key={`readmore-${readMoreBlock.position}`}
                            articles={readMoreBlock.articles}
                            title={__articles`readmore-block.title`}
                        />
                    </>
                );
            } else {
                injections[readMoreBlock.position] = readMore;
            }
        });

        const videoCarouselPlacement = getVideoCarouselPlacement(news.videofeedItem);
        const showVideoCarousel = videoCarouselPlacement === VideoCarouselPlacement.BODY;

        if (showVideoCarousel) {
            const currentPosition = news.videofeedItem?.position;

            if (typeof currentPosition !== 'undefined' && videofeedItem) {
                const VideoCarousel = currentPosition > 2 ? OnScrollVideoCarousel : ClientVideoCarousel;
                const showPromo = !!(moduleScheduleItem && news.adsEnabled);
                injections[currentPosition] = (
                    <>
                        {injections[currentPosition]}
                        <VideoCarousel mainVideo={videofeedItem} />
                        {showPromo ? (
                            <ArticlePromoItem
                                platform={platform?.id}
                                moduleScheduleItem={moduleScheduleItem}
                            />
                        ) : null}
                    </>
                );
            }
        }

        return [injections, bottomInjections];
    }, [
        __articles,
        moduleScheduleItem,
        news.adsEnabled,
        news.readMore,
        news.videofeedItem,
        upcomingMatch,
        videofeedItem,
        platform?.id,
    ]);

    return (
        <section className={`${styles.NewsBody}`}>
            {news.content?.length ? (
                <RichNextContent articleBlocks={news.content} injections={injections} />
            ) : (
                <HtmlToReact
                    html={news.newsText || ''}
                    injections={injections}
                    injectionIndex={1}
                    startWithInjection
                />
            )}
            {bottomInjections}
        </section>
    );
};

Component.displayName = 'NewsBody';
export const NewsBody = memo(Component);
