import { Fragment, memo, ReactElement } from 'react';
import dynamic from 'next/dynamic';

import { XPostProvider } from '@common/atoms/XPost/XPostProvider';
import { ArticleBlock, ArticleBlockType, Match } from '@common/clients/api';
import { htmlToReact } from '@web/atoms/HtmlToReact';
import { RELATED_GROUP_TYPE } from '@web/organisms/RelatedNewsBlock';
import { MatchBlockWrapper } from '@web/templates/CustomPage/atoms';

import { mapRelatedBlocksToGroups } from './utils';

const MediaBlock = dynamic(() => import('./atoms').then((mod) => mod.MediaBlock));
const RichImageBlock = dynamic(() =>
    import('@web/molecules/RickImageBlock/RichImageBlock').then((mod) => mod.RichImageBlock),
);
const YoutubeVideo = dynamic(() => import('@common/atoms/YoutubeVideo').then((mod) => mod.YoutubeVideo));
const InstagramPost = dynamic(() => import('@common/atoms/InstagramPost').then((mod) => mod.InstagramPost));
const IframeBlock = dynamic(() => import('@web/atoms/Iframe/Iframe').then((mod) => mod.Iframe));
const XPost = dynamic(() => import('@common/atoms/XPost').then((mod) => mod.XPost));
const JobsOverviewWidget = dynamic(() =>
    import('@common/atoms/JobsOverviewWidget').then((mod) => mod.JobsOverviewWidget),
);
const RelatedNewsBlock = dynamic(() =>
    import('@web/organisms/RelatedNewsBlock/RelatedNewsBlock').then((mod) => mod.RelatedNewsBlock),
);

const Paywall = dynamic(() => import('@common/atoms/Paywall').then((mod) => mod.Paywall));

const Frame = dynamic(() => import('@common/atoms/Frame').then((mod) => mod.Frame));

const QuoteBlock = dynamic(() =>
    import('@common/molecules/Quoteblock/QuoteBlock').then((mod) => mod.QuoteBlock),
);

interface RichNextContentProps {
    articleBlocks: ArticleBlock[];
    injections: ReactElement[];
    injectionIndex?: number;
    startWithInjection?: boolean;
    isPremiumUser?: boolean;
}

const RichNextContentComponent = ({
    articleBlocks,
    injections,
    injectionIndex = 0,
    startWithInjection,
    isPremiumUser,
}: RichNextContentProps) => {
    const mappedArticleBlocks = mapRelatedBlocksToGroups(articleBlocks);
    const blocks = mappedArticleBlocks.map((block, i) => {
        switch (block.type) {
            case ArticleBlockType.RICH_TEXT_BLOCK: {
                const result = htmlToReact({
                    html: block.text,
                    injectionIndex,
                    injections,
                    startWithInjection: startWithInjection && i === 0,
                });
                injectionIndex = result.injectionIndex;
                return <Fragment key={i}>{result.items}</Fragment>;
            }
            case ArticleBlockType.EMBED_BLOCK: {
                const result = htmlToReact({ html: block.embedBlock });
                return <Fragment key={i}>{result.items}</Fragment>;
            }
            case ArticleBlockType.FRAME_BLOCK: {
                return <Frame key={i} title={block.title} text={block.text} />;
            }
            case ArticleBlockType.HTML_BLOCK: {
                const result = htmlToReact({ html: block.htmlAndScript });
                return <Fragment key={i}>{result.items}</Fragment>;
            }
            case ArticleBlockType.IFRAME_BLOCK: {
                return <IframeBlock key={i} src={block.url} />;
            }
            case ArticleBlockType.MEDIA_BLOCK: {
                return <MediaBlock key={i} block={block} />;
            }
            case ArticleBlockType.IMAGE_BLOCK: {
                return <RichImageBlock key={i} block={block} />;
            }
            case ArticleBlockType.YOUTUBE_BLOCK: {
                return (
                    <YoutubeVideo key={i} videoID={block.videoID} isPortrait={block.isPortrait ?? false} />
                );
            }
            case ArticleBlockType.INSTAGRAM_BLOCK: {
                return <InstagramPost key={i} postID={block.postID} />;
            }
            case ArticleBlockType.X_POST_BLOCK: {
                return <XPost key={i} data={block} />;
            }
            case ArticleBlockType.MATCH_LIST_BLOCK: {
                return block.matches.map((match: Match) => (
                    <MatchBlockWrapper key={match.id} matchID={match.id} />
                ));
            }
            case ArticleBlockType.JOBS_OVERVIEW_BLOCK: {
                return <JobsOverviewWidget key={i} />;
            }
            case !isPremiumUser && ArticleBlockType.PAYWALL_BLOCK: {
                return <Paywall key={i} />;
            }
            case ArticleBlockType.RELATED_BLOCK:
            // TODO: relatedBlock only supports one article per block at the time. Remove the RELATED_GROUP_TYPE
            // once we have migrated all related blocks to the new format.
            case RELATED_GROUP_TYPE: {
                return <RelatedNewsBlock key={i} block={block} />;
            }
            case ArticleBlockType.STREAMER_BLOCK:
            case ArticleBlockType.QUOTE_BLOCK: {
                return <QuoteBlock key={i} block={block} />;
            }
            default: {
                return null;
            }
        }
    });

    return articleBlocks.some(({ type }) => type === ArticleBlockType.X_POST_BLOCK) ? (
        <XPostProvider>{blocks}</XPostProvider>
    ) : (
        blocks
    );
};

export const RichNextContent = memo(RichNextContentComponent);
