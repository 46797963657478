import { useCallback, useState } from 'react';
import clsx from 'clsx';

import { useTranslation } from '@cms/i18n/client';
import { Link } from '@common/atoms/Link';
import { Logo } from '@common/atoms/Logo';
import { logger } from '@common/logger';

import styles from './ShareBlock.module.scss';

export interface Props {
    newsTitle: string;
    newsPath: string;
    extended?: boolean;
    baseUrl?: string;
    isPremium?: boolean;
}

export const ShareBlock = ({ newsTitle = '', newsPath, extended, baseUrl, isPremium }: Props) => {
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const __articles = useTranslation('articles').t;

    const copyToClipboard = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');
        if (href) {
            navigator.clipboard
                ?.writeText(href)
                .then(() => {
                    setShowCopyMessage(true);
                    setTimeout(() => setShowCopyMessage(false), 2000);
                })
                .catch((err) => logger.error('There was an error copying the link.', err));
        }
    }, []);

    const encodedNewsHref = encodeURIComponent(baseUrl + newsPath);
    const encodedNewsTitle = encodeURIComponent(newsTitle);

    if (!baseUrl) {
        return null;
    }

    return (
        <div className={clsx(styles['share-block'], extended && styles['share-block--extended'])}>
            <ul className={clsx(styles['list'], isPremium && styles['list--premium'])}>
                <li className={styles['list-item']}>
                    <Link
                        href={`https://api.whatsapp.com/send?text=${encodedNewsHref}`}
                        data-action="share/whatsapp/share"
                    >
                        <a className={styles['link']} target="_blank">
                            <Logo.whatsapp />
                        </a>
                    </Link>
                </li>
                <li className={styles['list-item']}>
                    <Link
                        href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${encodedNewsHref}&t=${encodedNewsTitle}`}
                    >
                        <a className={styles['link']} target="_blank">
                            <Logo.facebook />
                        </a>
                    </Link>
                </li>
                {extended ? (
                    <>
                        <li className={styles['list-item']}>
                            <Link
                                href={`https://twitter.com/intent/tweet/?url=${encodedNewsHref}&text=${encodedNewsTitle}`}
                            >
                                <a className={styles['link']} target="_blank">
                                    <Logo.twitter />
                                </a>
                            </Link>
                        </li>
                        <li className={styles['list-item']}>
                            <Link href={`mailto:?subject=${encodedNewsTitle}&body=${encodedNewsHref}`}>
                                <a className={styles['link']}>
                                    <Logo.email />
                                </a>
                            </Link>
                        </li>
                    </>
                ) : null}
                <li className={clsx(styles['list-item'], styles['list-item--link'])}>
                    {showCopyMessage ? (
                        <span className={styles['copied-message']}>{__articles`action.copied-link`}</span>
                    ) : null}
                    <Link href={baseUrl + newsPath}>
                        <a className={styles['link']} onClick={copyToClipboard}>
                            <Logo.link />
                        </a>
                    </Link>
                </li>
            </ul>
        </div>
    );
};
