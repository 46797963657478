import { NewsReadMoreArticle, RelatedNewsItem, Tag } from '@common/clients/api';
import { BrandedHeading } from '@web/atoms/BrandedHeading';
import { SimpleNewsItem } from '@web/molecules/SimpleNewsList/atoms/SimpleNewsItem';

import { useSimpleNewsListThemeConfig } from './SimpleNewsList.config';

import styles from './SimpleNewsList.module.scss';

interface NewsReadMoreArticleWithTag extends NewsReadMoreArticle {
    tag?: Tag;
    commentCount?: number;
}

interface Props {
    title: string;
    articles: Array<NewsReadMoreArticleWithTag> | Array<RelatedNewsItem>;
}

const isNewsArticle = (
    article: NewsReadMoreArticleWithTag | RelatedNewsItem,
): article is NewsReadMoreArticleWithTag => {
    return 'newsTitle' in article;
};

export const SimpleNewsList = ({ title, articles }: Props) => {
    const themeConfig = useSimpleNewsListThemeConfig();

    if (!articles?.length) {
        return null;
    }

    return (
        <aside className={styles['SimpleNewsList']}>
            <BrandedHeading
                className={styles['title']}
                as="h3"
                color={themeConfig.brandedHeadingColor}
                icon={themeConfig.brandedHeadingIcon}
                variant={themeConfig.brandedHeadingVariant}
            >
                {title}
            </BrandedHeading>
            <div className={styles['container']}>
                {articles.map((article, index: number) => {
                    const commentCount = isNewsArticle(article) ? article.commentCount || 0 : 0;
                    const newsTitle = isNewsArticle(article) ? article.newsTitle : article.title || '';
                    return (
                        <SimpleNewsItem
                            key={`$item-${index}`}
                            newsTitle={newsTitle || ''}
                            article={article}
                            commentCount={commentCount}
                        />
                    );
                })}
            </div>
        </aside>
    );
};
