import Link from 'next/link';

import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { Author } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Avatar } from '@web/atoms/Avatar/Avatar';
import { HtmlToReact } from '@web/atoms/HtmlToReact';
import { formatRoute, Route } from '@web/routing';

import styles from './AboutWriter.module.scss';

export interface Props {
    author: Author;
}

export const AboutWriter = ({ author }: Props) => {
    const { context } = useContextData();
    const __url = useTranslation('url').t;

    const __meta = useTranslation('meta').t;

    const authorUrl = formatRoute(context, Route.User, __url, {
        slug: author?.customSlug ? author.customSlug : author.userID,
    });

    return (
        <article className={styles.AboutWriter}>
            <div className={styles['author-meta']}>
                <Avatar src={author.image} isRounded isPremium />
                <h1 className={styles['author-fullname']}>{author.fullName}</h1>
                <span className={styles['author-function']}>{author.function}</span>
                <p className={styles['author-bio']}>
                    <HtmlToReact html={author.bio || ''} />
                </p>
                <Link href={authorUrl}>
                    <button className={styles['author-button']}>
                        {__meta(`aboutWriter.more`)} {author.fullName} {<Icon.rightCenter />}
                    </button>
                </Link>
            </div>
        </article>
    );
};
