import { Bookmaker, ModuleScheduleItem } from '@common/clients/api/types.gen';

export const getBookmaker = (
    moduleScheduleItem: ModuleScheduleItem,
    bookmakerID: number,
): [Bookmaker | undefined, boolean] => {
    if (moduleScheduleItem.bookmaker && bookmakerID === moduleScheduleItem.bookmaker.id) {
        return [moduleScheduleItem.bookmaker, true];
    } else if (
        moduleScheduleItem.secondaryBookmaker &&
        bookmakerID === moduleScheduleItem.secondaryBookmaker.id
    ) {
        return [moduleScheduleItem.secondaryBookmaker, false];
    }
    return [undefined, false];
};
