import type { Translate } from '@cms/i18n';

import { Link } from '@common/atoms/Link';
import { News } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import { useUserContext } from '@common/useUserContext/UserContextProvider';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { DefaultDynamicSizes, WebpImage } from '@web/atoms/WebpImage';
import { Embed } from '@web/templates/NewsDetail/organisms/NewsHeader/molecules/Embed';
import { hasEditorOrAdminAccess } from '@web/utils/userRole';

import styles from './Hero.module.scss';

export interface Props {
    adminUrl: string;
    news: News;
    __translate: Translate;
    isPremium: boolean;
}

const getCopyrightText = (
    copyright: string | undefined,
    credit: string | undefined,
    altText: string | undefined,
): string | undefined => {
    const pieces: string[] = [];
    if (copyright) pieces.push(copyright);
    if (credit) pieces.push(credit);
    if (pieces.length) {
        const result = pieces.join(' - ');
        return !/©/.test(result) ? `© ${result}` : result;
    }
    return altText;
};

export const Hero = ({ adminUrl, news, __translate, isPremium }: Props) => {
    const {
        context: { locale },
    } = useContextData();
    const { user } = useUserContext();
    const hasEditRights = hasEditorOrAdminAccess(user?.role);

    const altText = news.imageTitle || news.metaTitle;
    const copyrightText = getCopyrightText(news.imageCopyright, news.imageCredit, news.imageTitle);
    const showEmbed = Boolean(news.newsVideo && !news.videofeedItem);
    const showImage = !showEmbed && news?.image;

    const classes: string[] = [styles.Hero];
    if (isPremium) classes.push(styles['premium']);
    if (showEmbed) classes.push(styles['has-embed']);
    if (hasEditRights) classes.push(styles['has-edit']);

    return (
        <figure className={classes.join(' ')} title={showImage ? altText : undefined}>
            {hasEditRights ? (
                <div className={styles['edit']}>
                    <p>{DateTimeUtil.format(news.newsDateUpdate, Format.DATE_TIME_LONG, locale)}</p>
                    <Link href={adminUrl} passHref={true}>
                        <button>{__translate`header.edit`}</button>
                    </Link>
                </div>
            ) : null}
            {!isPremium && copyrightText ? <p className={styles['image-label']}>{copyrightText}</p> : null}
            {showEmbed ? (
                <Embed embedContent={news.newsVideo || ''} />
            ) : showImage ? (
                <>
                    <span className={styles['pro-gradient']} />
                    <WebpImage
                        alt={altText}
                        defaultSize={WebpWidthEnum.WIDTH_450}
                        ratio={1755 / 1000}
                        src={news?.image}
                        sizes={isPremium ? '100vw' : DefaultDynamicSizes.TwoThird}
                        priority={true}
                        position="50% 30%"
                    />
                    <span className={styles['pro-gradient']} />
                </>
            ) : null}
        </figure>
    );
};
