import { ModuleScheduleItem, ThreeWayOdds } from '@common/clients/api/types.gen';

import { getBookmaker } from './getBookmaker';

export const formatOddsUrl = (moduleScheduleItem: ModuleScheduleItem, odds: ThreeWayOdds): ThreeWayOdds => {
    if (!odds) return odds;
    const result: ThreeWayOdds = { ...odds };

    const bookmakerID = result.id;
    const [bookmaker, isPrimaryBookmaker] = getBookmaker(moduleScheduleItem, bookmakerID);
    if (bookmaker) result.bookmaker = bookmaker;

    let deeplinkUrl = odds?.deepLinkUrl ?? '';
    const bookmakerUrl = bookmaker?.url ?? '';
    if (deeplinkUrl && bookmakerUrl) {
        new URL(bookmakerUrl).searchParams.forEach((value, key) => {
            deeplinkUrl = deeplinkUrl.replace(new RegExp(`{${key}}`, 'gi'), value);
        });
    } else if (!deeplinkUrl) {
        deeplinkUrl = bookmakerUrl;
    }
    result.url = deeplinkUrl;

    result.isPrimaryBookmaker = isPrimaryBookmaker;

    return result;
};
