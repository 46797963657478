import { Translate } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { LocaleID } from '@common/clients/api';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';

import styles from './DateMeta.module.scss';

export interface Props {
    newsDate: string;
    newsDateUpdate?: string;
    __translate: Translate;
    locale: LocaleID;
    isPremium: boolean;
}

export const DateMeta = ({ newsDate, newsDateUpdate, __translate, locale, isPremium }: Props) => {
    const currentDate = new Date();
    const articleDate = new Date(newsDate);

    const [isThisYear, isThisMonth, isThisDay] = [
        currentDate.getFullYear() === articleDate.getFullYear(),
        currentDate.getMonth() === articleDate.getMonth(),
        currentDate.getDate() === articleDate.getDate(),
    ];

    const isToday = isThisYear && isThisMonth && isThisDay;

    return (
        <div
            className={`${styles.DateMeta} ${newsDateUpdate ? styles['update'] : ''} ${
                isPremium ? styles['premium'] : ''
            }`}
        >
            <Icon.time />
            <div className={`${newsDateUpdate ? styles['date-container'] : ''}`}>
                <div className={styles['current-date']}>
                    <time dateTime={DateTimeUtil.format(newsDate, Format.DATE_TIME_ISO)}>
                        {isToday
                            ? `${__translate`header.today`}` +
                              `${__translate`header.at`}` +
                              DateTimeUtil.format(newsDate, Format.TIME_NUMERIC, locale)
                            : DateTimeUtil.format(newsDate, Format.DATE_MEDIUM_OPTIONAL_YEAR, locale) +
                              `${__translate`header.at`}` +
                              DateTimeUtil.format(newsDate, Format.TIME_NUMERIC, locale)}
                    </time>
                </div>

                {newsDateUpdate ? (
                    <span className={styles['date-update']}>
                        {isToday
                            ? `${__translate`header.update`}` +
                              `${__translate`header.at`}` +
                              DateTimeUtil.format(newsDateUpdate, Format.TIME_NUMERIC, locale)
                            : `${__translate`header.update`}` +
                              DateTimeUtil.format(newsDateUpdate, Format.DATE_MEDIUM_OPTIONAL_YEAR, locale) +
                              `${__translate`header.at`}` +
                              DateTimeUtil.format(newsDateUpdate, Format.TIME_NUMERIC, locale)}
                    </span>
                ) : null}
            </div>
        </div>
    );
};
