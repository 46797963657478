import { memo } from 'react';

import { HtmlToReact } from '@web/atoms/HtmlToReact';
import { Iframe } from '@web/atoms/Iframe';

interface Props {
    embedContent: string;
}

const YOUTUBE_EMBED_REGEX = /youtu?.be/i;
const DAILY_MOTION_EMBED_REGEX = /dai?.ly/i;

const isVideoEmbedUrl = (str: string) =>
    [DAILY_MOTION_EMBED_REGEX, YOUTUBE_EMBED_REGEX].some((videoUrl) => videoUrl.test(str));

const formatVideoEmbedUrl = (url: string) => {
    const urlParts = url.split('/');
    const suffix = urlParts[urlParts.length - 1];

    if (DAILY_MOTION_EMBED_REGEX.test(url)) {
        return `https://www.dailymotion.com/embed/video/${suffix}`;
    }
    if (YOUTUBE_EMBED_REGEX.test(url)) {
        return `https://www.youtube.com/embed/${suffix}`;
    }
    // Already formatted as an iframe url
    return url;
};

const Component = ({ embedContent }: Props) => {
    const isVideoUrl = isVideoEmbedUrl(embedContent);
    return (
        <>
            {isVideoUrl ? (
                <Iframe src={formatVideoEmbedUrl(embedContent)} />
            ) : (
                <HtmlToReact html={embedContent} />
            )}
        </>
    );
};

Component.displayName = 'Embed';
export const Embed = memo(Component);
