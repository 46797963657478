import { ArticleBlock, ArticleBlockType } from '@common/clients/api';
import { RELATED_GROUP_TYPE, RelatedGroup } from '@web/organisms/RelatedNewsBlock';

// TODD: relatedBlock only supports one article per block at the time. Remove the mapRelatedBlocksToGroups util function
// once we have migrated all related blocks to the new format.
export const mapRelatedBlocksToGroups = (articleBlocks: ArticleBlock[]): (ArticleBlock | RelatedGroup)[] => {
    if (!articleBlocks) return [];

    return articleBlocks.reduce<(ArticleBlock | RelatedGroup)[]>((result, block, index, array) => {
        if (block.type !== ArticleBlockType.RELATED_BLOCK) {
            return [...result, block];
        }

        const relatedBlock = block;
        const nextBlockIsRelated = array[index + 1]?.type === ArticleBlockType.RELATED_BLOCK;
        const lastGroup = result[result.length - 1];

        const previousWasRelatedGroup =
            lastGroup && 'type' in lastGroup && lastGroup.type === RELATED_GROUP_TYPE;

        // If there's an existing related group, add to it
        if (previousWasRelatedGroup) {
            lastGroup.items.push(relatedBlock.item);
            return result;
        }

        // If this is a single related block, keep it as is
        if (!nextBlockIsRelated) {
            return [...result, relatedBlock];
        }

        // Create new related group
        const newGroup: RelatedGroup = {
            type: RELATED_GROUP_TYPE,
            items: [relatedBlock.item],
        };

        return [...result, newGroup];
    }, []);
};
