import { Author } from '@common/clients/api';
import { Avatar } from '@web/atoms/Avatar/Avatar';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import styles from './AuthorMeta.module.scss';

export interface Props {
    author: Author;
    isPremium: Boolean;
}

export const AuthorMeta = ({ author, isPremium }: Props) =>
    author.customSlug ? (
        <div className={`${styles.AuthorMeta} ${isPremium ? styles['premium'] : ''}`}>
            <InternalLink route={Route.User} query={{ slug: author.customSlug }}>
                {author.image ? <Avatar className={styles['avatar']} src={author.image} isRounded /> : null}
                <ul>
                    {author.fullName ? <li>{author.fullName}</li> : null}
                    {author.function ? <li>{author.function}</li> : null}
                </ul>
            </InternalLink>
        </div>
    ) : (
        <div className={`${styles.AuthorMeta} ${isPremium ? styles['premium'] : ''}`}>
            {author.image ? <Avatar className={styles['avatar']} src={author.image} isRounded /> : null}
            <ul>
                {author.fullName ? <li>{author.fullName}</li> : null}
                {author.function ? <li>{author.function}</li> : null}
            </ul>
        </div>
    );
