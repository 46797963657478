import { useTranslation } from '@cms/i18n/client';
import { RelatedBlock, RelatedNewsItem } from '@common/clients/api';
import { SimpleNewsList } from '@web/molecules/SimpleNewsList';

export const RELATED_GROUP_TYPE = 'RELATED_GROUP';

interface RelatedNewsProps {
    block: RelatedBlock | RelatedGroup;
}

export interface RelatedGroup {
    type: 'RELATED_GROUP';
    items: RelatedNewsItem[];
}

const isRelatedGroup = (block: RelatedBlock | RelatedGroup): block is RelatedGroup => {
    return block.type === RELATED_GROUP_TYPE;
};

const getArticles = (block: RelatedBlock | RelatedGroup): RelatedNewsItem[] => {
    if (isRelatedGroup(block)) {
        return block.items;
    }
    return [block.item];
};

export const RelatedNewsBlock = ({ block }: RelatedNewsProps) => {
    const { t } = useTranslation('related-news');

    const articles = getArticles(block);

    return <SimpleNewsList title={t('readmore')} articles={articles} />;
};
