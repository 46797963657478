import clsx from 'clsx';
import dynamic from 'next/dynamic';

import { useTranslation } from '@cms/i18n/client';
import { ModuleScheduleItem, News, PartialTag } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { TagList } from '@web/molecules/TagList';
import { VideoCarousel as OnScrollVideoCarousel } from '@web/organisms/VideoCarousel/OnScrollLoadedVideoCarousel';

import { AboutWriter } from '../../molecules/AboutWriter';
import { ShareBlock } from '../../molecules/ShareBlock';
import { getVideoCarouselPlacement, VideoCarouselPlacement } from '../../utils';

import styles from './NewsFooter.module.scss';

const ArticlePromoItem = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/ArticlePromoItem').then((module) => module.ArticlePromoItem),
);

interface Props {
    news: News;
    tags?: PartialTag[];
    moduleScheduleItem?: ModuleScheduleItem;
    isPremium?: boolean;
}

export const NewsFooter = ({ news, tags = [], moduleScheduleItem, isPremium }: Props) => {
    const { platform } = useContextData();
    const __articles = useTranslation('articles').t;

    const videoCarouselPlacement = getVideoCarouselPlacement(news?.videofeedItem);
    const showVideoCarousel =
        videoCarouselPlacement === VideoCarouselPlacement.FOOTER ||
        videoCarouselPlacement === VideoCarouselPlacement.FOOTER_NO_VIDEOFEEDITEM;

    const showPromo = !!(moduleScheduleItem && news.adsEnabled);

    return (
        <footer>
            <div className={styles['sections']}>
                {tags.length || news.category?.showAsTag ? (
                    <section className={clsx(styles['section'], styles['section--tag'])}>
                        <h3 className={styles['section-title']}>{__articles`footer.read-more-about`}</h3>
                        <div className={styles['section-content']}>
                            <TagList tags={tags} category={news.category} />
                        </div>
                    </section>
                ) : null}
                <section className={clsx(styles['section'], styles['section--share'])}>
                    <h3 className={styles['section-title']}>{__articles`footer.share-article`}</h3>
                    <div className={styles['section-content']}>
                        <ShareBlock
                            newsTitle={news.newsTitle || ''}
                            newsPath={news.path || ''}
                            baseUrl={news.host}
                            extended
                        />
                    </div>
                </section>
            </div>
            {isPremium && news.authorUserID && news.author ? <AboutWriter author={news.author} /> : null}
            {showVideoCarousel ? (
                <>
                    <OnScrollVideoCarousel
                        mainVideo={news?.videofeedItem?.id ? news?.videofeedItem : undefined}
                    />
                    {showPromo ? (
                        <ArticlePromoItem platform={platform?.id} moduleScheduleItem={moduleScheduleItem} />
                    ) : null}
                </>
            ) : null}
        </footer>
    );
};
