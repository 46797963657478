import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon/Icon';

import styles from './CommentCount.module.scss';

interface CommentCountProps {
    count: number;
    className?: string;
}

export const CommentCount = ({ count, className }: CommentCountProps) => {
    const __meta = useTranslation('meta').t;
    return (
        <div className={`${styles.CommentCount} ${className}`}>
            <Icon.comment />
            <span className={styles['count-text']}>
                {count === 1
                    ? __meta('comments.one', { count: count })
                    : __meta('comments.other', { count: count })}
            </span>
        </div>
    );
};
