import { useEffect, useState } from 'react';

import { getMatch } from '@admin/molecules/RichEditor/Editor/helpers';
import { Match } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { formatOddsGroupsUrls } from '@common/utils/betting';
import { HighlightedMatch } from '@web/organisms/HighlightedMatch';

export const MatchBlockWrapper = ({ matchID, match }: { matchID?: string; match?: Match }) => {
    const [currentMatch, setMatch] = useState<Match | undefined>(match);
    const contextData = useContextData();
    const { modulesBySection } = useContextData();
    const articleMatches = modulesBySection?.articleMatches?.[0];

    useEffect(() => {
        if (match?.id || matchID) {
            getMatch({
                matchID: match?.id || matchID || '',
                contextData,
                moduleScheduleItem: articleMatches,
            }).then(setMatch);
        }
    }, [matchID, contextData, match?.id, articleMatches]);

    if (!currentMatch) return null;

    if (currentMatch.odds && articleMatches) {
        currentMatch.odds = formatOddsGroupsUrls(articleMatches, currentMatch.odds);
    }

    return <HighlightedMatch match={currentMatch} showOdds={true} isCompactMatchblock />;
};
