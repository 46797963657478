'use client';

import { ReactElement, useEffect, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';

import {
    CommentFull,
    FeaturedItem,
    Match,
    ModuleScheduleSection as ModuleSection,
    News,
    NewsStatus,
    NewsThumb,
    Pagination,
} from '@common/clients/api';
import { DefaultServerSideProps } from '@common/types/DefaultProps';
import { useContextData } from '@common/useContextData';
import { stripHtml } from '@common/utils/stripHtml';
import { HtmlToReact } from '@web/atoms/HtmlToReact';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import type { CrossPlatformPromoProps } from '@web/molecules/CrossPlatformPromo';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { NewsList } from '@web/molecules/NewsList';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { NewsArticleStructuredData, VideoStructuredData } from '@web/molecules/StructuredData';
import { CommentsThread } from '@web/organisms/CommentsThread';
import { MetaHead } from '@web/organisms/MetaHead';
import type { RegularPollProps } from '@web/organisms/Poll';
import { Sidebar } from '@web/organisms/Sidebar';
import { Route } from '@web/routing';
import { increaseVisitCount } from '@web/templates/NewsDetail/handlers';
import { isNotRobot } from '@web/templates/NewsDetail/handlers/increaseVisitCount';

import { NewsBody } from './organisms/NewsBody';
import { NewsFooter } from './organisms/NewsFooter';
import { NewsHeader } from './organisms/NewsHeader';

const ArticlePromoItem = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/ArticlePromoItem').then((module) => module.ArticlePromoItem),
);

const BinaryPoll = dynamic(() =>
    import('@web/organisms/Poll/BinaryPoll').then((module) => module.BinaryPoll),
);

const RegularPoll = dynamic(() =>
    import('@web/organisms/Poll/RegularPoll').then((module) => module.RegularPoll),
);

const MatchDetailSection = dynamic(() =>
    import('@web/organisms/MatchDetailSection').then((module) => module.MatchDetailSection),
);

const CrossPlatformPromo = dynamic(() =>
    import('@web/molecules/CrossPlatformPromo').then((module) => module.CrossPlatformPromo),
);

export interface State {
    crossPlatformPromoItems?: FeaturedItem[];
    news?: News;
    newsList?: { pagination?: Pagination; data?: News[] };
    comments?: CommentFull[];
    popularComments?: CommentFull[];
    userNewsThumb?: NewsThumb[];
    flaggedComments?: number[];
    ignoredUsers?: number[];
    match?: Match;
    upcomingMatch?: Match;
    currentPage?: number;
}

export interface Props {
    state: State;
    showOdds: boolean;
    crossPlatformPromoProps?: CrossPlatformPromoProps;
    pollProps?: RegularPollProps;
    marketingBlock?: string | null;
}

export const NewsDetail = ({
    state,
    showOdds,
    contextData,
    marketingBlock,
    crossPlatformPromoProps,
    pollProps,
}: Props & DefaultServerSideProps) => {
    const { allowMyPXR, context, platform, experimentGroup, modulesBySection } = useContextData();

    const news = state.news;
    const injections: ReactElement[] = [];

    injections[5] = <Ad isOptional placement={AdPlacement.ARTICLE_AFTER_COMMENT_5} />;
    injections[10] = <Ad isOptional placement={AdPlacement.ARTICLE_AFTER_COMMENT_10} />;
    injections[15] = <Ad isOptional placement={AdPlacement.ARTICLE_AFTER_COMMENT_15} />;
    injections[20] = <Ad isOptional placement={AdPlacement.ARTICLE_AFTER_COMMENT_20} />;
    injections[25] = <Ad isOptional placement={AdPlacement.ARTICLE_AFTER_COMMENT_25} />;

    const newsListInjections: ReactElement[] = [];
    newsListInjections[5] = (
        <Ad key="NewsListAd" isOptional placement={AdPlacement.NON_HOME_AFTER_ARTICLE_5} />
    );

    const pageViewUpdated = useRef<boolean | null>(null);
    useEffect(() => {
        if (news?.newsID && isNotRobot(experimentGroup) && pageViewUpdated.current === null) {
            pageViewUpdated.current = false;
            increaseVisitCount(contextData, news?.newsID, experimentGroup).then(
                () => (pageViewUpdated.current = true),
            );
        }
    }, [experimentGroup, news, contextData]);

    const noIndex = useMemo(() => news?.status !== NewsStatus.PUBLISHED, [news?.status]);

    if (!news) return null;

    const metaDescription = news.metaDescription || stripHtml(news.newsBold);
    const socialTitle = news.socialTitle || news.newsTitle || news.metaTitle || '';
    const socialDescription = news.socialDescription || metaDescription;
    const categories = news.tags
        ?.map((tag) => tag.title)
        .filter<string>((tag): tag is string => typeof tag === 'string' && tag.length > 0);

    return (
        <>
            <MetaHead
                title={news.newsTitle || ''}
                description={stripHtml(news.newsBold)}
                categories={categories}
                image={news.image}
                noIndex={noIndex}
                parents={news.category?.isMedia ? [Route.VideoOverview] : []}
                skipDefaultStructuredData={true}
                socialDescription={socialDescription}
                socialTitle={socialTitle}
            />
            <NewsArticleStructuredData
                article={news}
                speakable={{ cssSelector: ['article.main-article section.content p'] }}
            />
            <VideoStructuredData article={news} video={news.videofeedItem} />
            <MatchTicker moduleScheduleItem={modulesBySection?.oddsMatchTicker?.[0]} />
            <div className="main-content">
                <article role="main">
                    <NewsHeader
                        news={news}
                        context={context}
                        moduleScheduleItem={modulesBySection?.promoElementBelowVideoCarousel?.[0]}
                    />
                    <NewsBody
                        news={news}
                        upcomingMatch={state.upcomingMatch}
                        moduleScheduleItem={modulesBySection?.promoElementBelowVideoCarousel?.[0]}
                    />
                    {news.binaryPoll ? <BinaryPoll stats={news.binaryPoll} newsID={news.newsID} /> : null}
                    {news.adsEnabled && modulesBySection?.promoElementBelowArticle?.[0] ? (
                        <ArticlePromoItem
                            platform={platform?.id}
                            moduleScheduleItem={modulesBySection?.promoElementBelowArticle?.[0]}
                        />
                    ) : null}
                    {marketingBlock ? (
                        <aside>
                            <HtmlToReact html={marketingBlock} />
                        </aside>
                    ) : null}
                    {state.match ? <MatchDetailSection match={state.match} showOdds={showOdds} /> : null}
                    {news.adsEnabled === false ? null : (
                        <Ad isOptional placement={AdPlacement.ARTICLE_BEFORE_TAGS} />
                    )}
                    <NewsFooter
                        tags={news.tags}
                        news={news}
                        moduleScheduleItem={modulesBySection?.promoElementBelowVideoCarousel?.[0]}
                    />
                    {news.adsEnabled === false ? null : (
                        <Ad
                            key="AdPlacement.ARTICLE_AFTER_TAGS"
                            isOptional
                            placement={AdPlacement.ARTICLE_AFTER_TAGS}
                        />
                    )}
                    {pollProps ? <RegularPoll {...pollProps} /> : null}
                    {
                        /*
                         * CrossPlatformPromo
                         * Might need to inject it between the post comments and the comments thread */
                        crossPlatformPromoProps &&
                        state.crossPlatformPromoItems &&
                        state.crossPlatformPromoItems.length > 0 ? (
                            <CrossPlatformPromo
                                items={state.crossPlatformPromoItems}
                                themeID={crossPlatformPromoProps.theme}
                            />
                        ) : null
                    }
                    {allowMyPXR && news.newsCommentsEnabled ? (
                        <CommentsThread
                            newsID={news.newsID}
                            commentCount={news?.commentCount}
                            injections={injections}
                            moduleScheduleInjection={modulesBySection?.[ModuleSection.COMMENTS]?.[0]}
                        />
                    ) : null}
                </article>
                {state.newsList?.data?.length ? (
                    <NewsList
                        isAside={true}
                        items={state.newsList.data}
                        injections={newsListInjections}
                        promoItems={modulesBySection?.tagNewsList}
                    />
                ) : null}
            </div>
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
