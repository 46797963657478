import { Icon } from '@common/atoms/Icon/Icon';
import { Link } from '@common/atoms/Link';
import { NewsReadMoreArticle, RelatedNewsItem } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { TagElement } from '@web/atoms/TagElement';
import { WebpImage } from '@web/atoms/WebpImage';
import { CommentCount } from '@web/molecules/CommentCount';

import styles from './SimpleNewsItem.module.scss';

const RATIO = WebpWidthEnum.WIDTH_110 / 60;

interface Props {
    article: NewsReadMoreArticle | RelatedNewsItem;
    commentCount: number;
    newsTitle: string;
}

function isNewsReadMoreArticle(
    article: NewsReadMoreArticle | RelatedNewsItem,
): article is NewsReadMoreArticle {
    return 'newsTitle' in article;
}

export const SimpleNewsItem = ({ article, commentCount, newsTitle }: Props) => {
    const headingLineLimit = commentCount > 0 ? styles['limit-2'] : styles['limit-3'];
    const title = isNewsReadMoreArticle(article) ? article.newsTitle : article.title;
    const path = isNewsReadMoreArticle(article) ? article.path : article.url;
    const image = isNewsReadMoreArticle(article) ? article.image : article.imageUrl;
    const imageAlt = `Thumbnail for article: ${title}`;

    return (
        <article className={styles.SimpleNewsItem}>
            <Link href={path ?? ''}>
                <WebpImage
                    defaultSize={WebpWidthEnum.WIDTH_110}
                    src={image ?? ''}
                    alt={imageAlt}
                    className={styles.thumbnail}
                    ratio={RATIO}
                />
                <div className={styles.content}>
                    <h4 className={`${styles['article-title']} ${headingLineLimit}`}>
                        <TagElement item={article} />
                        {newsTitle}
                    </h4>
                    {commentCount > 0 ? <CommentCount count={commentCount} /> : null}
                </div>
                <Icon.right />
            </Link>
        </article>
    );
};
